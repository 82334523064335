<template>
  <div>
    <div class="text-general row mb-3">
      <h1>
        GitHub statistics for repository
        <code><input id="repo-name" type="text" v-model="repo" /></code>
      </h1>
    </div>

    <div class="text-center mb-3" v-if="status === 'loading'">
      <div
        class="spinner-border mt-3"
        role="status"
        style="width: 3rem; height: 3rem"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="text-center mb-3" v-if="status === 'error'">
      <p class="lead">Repository not found</p>
    </div>

    <LineChart v-if="status === 'ready'" v-bind="lineChartProps" />
  </div>
</template>

<script>
import { LineChart, useLineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import axios from "axios";

const graphOptions = {
  scales: {
    LoC: {
      axis: "y",
      title: {
        text: "Lines of Code",
        display: true,
      },
      ticks: {
        display: true,
        maxTicksLimit: 5,
      },
      grid: {
        display: true,
      },
      display: true,
    },
    date: {
      axis: "x",
      title: {
        text: "Time",
        display: true,
      },
      display: true,
      grid: {
        display: false,
      },
    },
  },
};

Chart.register(...registerables);

export default {
  name: "Charts",
  components: { LineChart },
  data() {
    return {
      lineChartProps: undefined,
      lineChartRef: undefined,
      repo: "",
      status: "",
      debounceTimer: undefined,
    };
  },
  methods: {
    fetchData(repo) {
      this.repo = repo;
      this.status = "loading";
      axios
        .get("https://api.github.com/repos/" + repo + "/stats/code_frequency")
        .then((result) => {
          console.log(result);
          this.status = "ready";
          let additions = [];
          let deletions = [];
          let labels = [];
          for (let point of result.data.slice(-50)) {
            labels.push(" ");
            additions.push(point[1]);
            deletions.push(point[2] * -1);
          }
          this.displayData(labels, additions, deletions);
        })
        .catch((error) => {
          this.status = "error";
          console.log(error);
        });
    },
    displayData(labels, additions, deletions) {
      let chartData = {
        labels: labels,
        datasets: [
          {
            label: "Additions",
            pointRadius: 2,
            borderColor: "#3a8168",
            backgroundColor: "#3a8168",
            data: additions,
            lineTension: 0.5,
          },
          {
            label: "Deletions",
            pointRadius: 2,
            borderColor: "#dc6846",
            backgroundColor: "#dc6846",
            data: deletions,
            lineTension: 0.5,
          },
        ],
      };
      let { lineChartProps, lineChartRef } = useLineChart({
        chartData: chartData,
        options: graphOptions,
      });
      this.lineChartProps = lineChartProps;
      this.lineChartRef = lineChartRef;
    },
  },
  watch: {
    repo(value) {
      if (value === "") return;

      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(() => {
        this.fetchData(value);
      }, 1000);
    },
  },
  mounted() {
    this.fetchData("torvalds/linux");
  },
};
</script>

<style scoped>
#repo-name {
  color: #007bff;
  border: none;
  background-color: transparent;
}

#repo-name:focus {
  outline: none;
}
</style>
